@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Instrument+Serif:ital@0;1&display=swap");

.btn-primary {
  @apply bg-green-600 rounded-md text-white px-6 py-3 border-[1px] border-green-700 shadow-inner;
}

input[type="password"]::-ms-reveal {
  display: none;
}

.gradient-text {
  background: linear-gradient(
    90deg,
    #06b6d4,
    #3b82f6
  ); /* Your gradient colors */
  background-clip: text;
  color: transparent;
  font-weight: bold;
  display: block;
}

.instrument-serif-regular {
  font-family: "Instrument Serif", serif;
  font-weight: 400;
  font-style: normal;
}

.instrument-serif-regular-italic {
  font-family: "Instrument Serif", serif;
  font-weight: 400;
  font-style: italic;
}

:root {
  /* Custom properties for scrollbar */
  --scrollbar-width: 8px;
  --scrollbar-thumb-color: #a8a8a8; /* Neutral gray color */
  --scrollbar-thumb-border: #2e2e2e; /* Light gray for border */
  --scrollbar-track-color: #292929; /* Light gray for track */
}

.custom-scrollbar {
  /* Scrollbar width for vertical and horizontal scrollbars */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: var(--scrollbar-thumb-color) var(--scrollbar-track-color); /* For Firefox */
}

.custom-scrollbar::-webkit-scrollbar {
  width: var(--scrollbar-width);
  height: var(--scrollbar-width);
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb-color);
  border-radius: 10px;
  border: 2px solid var(--scrollbar-thumb-border);
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: var(--scrollbar-track-color);
}
.scroll-none::-webkit-scrollbar{
  display: none
}

.unreset {
  a {
    @apply text-white underline;
  }
  p {
    @apply my-4;
  }

  blockquote,
  figure {
    @apply my-4 mx-10;
  }

  hr {
    @apply border;
  }

  h1 {
    @apply text-4xl font-bold my-2;
  }

  h2 {
    @apply text-2xl font-bold my-3;
  }

  h3 {
    @apply text-lg font-bold my-4;
  }

  h4 {
    @apply text-base font-bold my-5;
  }

  h5 {
    @apply text-sm font-bold my-6;
  }

  h6 {
    @apply text-xs font-bold my-10;
  }

  article,
  aside,
  nav,
  section {
    h1 {
      @apply text-2xl font-bold my-3;
    }

    article,
    aside,
    nav,
    section {
      h1 {
        @apply text-lg font-bold my-4;
      }

      article,
      aside,
      nav,
      section {
        h1 {
          @apply text-base font-bold my-5;
        }

        article,
        aside,
        nav,
        section {
          h1 {
            @apply text-sm font-bold my-6;
          }

          article,
          aside,
          nav,
          section {
            h1 {
              @apply text-xs font-bold my-10;
            }
          }
        }
      }
    }
  }

  ul,
  menu {
    @apply list-disc my-1 pl-10;
  }

  ol {
    @apply list-decimal my-4 pl-10;
  }

  ul,
  ol {
    ul {
      list-style-type: circle;
    }

    ul,
    ol {
      ul {
        list-style-type: square;
      }
    }
  }

  dd {
    @apply pl-10;
  }

  dl {
    @apply my-4;
  }

  ul,
  ol,
  menu,
  dl {
    ul,
    ol,
    menu,
    dl {
      margin: 0;
    }
  }

  legend {
    @apply py-0 px-1;
  }

  fieldset {
    @apply my-0 mx-1 pt-0 px-1 pb-2;
  }

  b,
  strong {
    font-weight: bold;
  }

  pre {
    @apply my-4;
  }
}

.markDownContainer {
  /* Background and Text */
  body {
    background-color: #1e1e1e;
    color: #e0e0e0;
  }

  /* Links */
  a {
    color: #3765d7;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }

  /* Text Styles */
  strong,
  b {
    font-weight: bold;
    color: #f0f0f0;
  }

  em,
  i {
    font-style: italic;
    color: #cccccc;
  }

  blockquote {
    border-left: 4px solid #ff6b6b;
    padding-left: 10px;
    color: #cccccc;
    margin: 15px 0;
    font-style: italic;
    background-color: #2a2a2a;
    padding: 10px 20px;
    border-radius: 4px;
  }

  /* Lists */
  ul,
  ol {
    margin: 10px 0 10px 20px;
  }

  /* Inline Code */
  code {
    background-color: #2d2d2d;
    color: #d4d4d4;
    padding: 2px 4px;
    border-radius: 4px;
    font-size: 14px;
    font-family: Consolas, "Courier New", monospace;
  }

  /* Block Code */
  pre code {
    background-color: #2d2d2d;
    color: #d4d4d4;
    display: block;
    padding: 15px;
    border-radius: 8px;
    font-size: 15px;
    overflow-x: auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
    margin: 10px 0;
  }

  /* Horizontal Rules */
  hr {
    border: none;
    border-top: 1px solid #444;
    margin: 20px 0;
  }

  /* Tables */
  table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    background-color: #2a2a2a;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  }

  th,
  td {
    border: 1px solid #444;
    padding: 12px 15px;
    text-align: left;
  }

  th {
    background-color: #333;
    color: #e0e0e0;
    font-weight: bold;
  }

  td {
    color: #bfbfbf;
  }

  /* Images */
  img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 10px 0;
    border-radius: 4px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
  }
}
